import { BaseService } from './base.service';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Empresa } from 'src/app/shared/models/empresa';
import { HttpClient } from '@angular/common/http';
import { ListaPaginadaEmpresa } from 'src/app/shared/models/listas-paginadas/listaPaginadaEmpresa';
import { FiltroEmpresa } from 'src/app/shared/models/filtros/filtroEmpresa';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService extends BaseService {

  notificadorAlterarEmpresa = new EventEmitter();

  constructor(private http: HttpClient) {
    super();
  }

  notificarAlterarEmpresa() {
    this.notificadorAlterarEmpresa.emit();
  }

  obterPeloId(id: string): Observable<Empresa> {
    return this.http.get<Empresa>(this.urlService + 'empresa/obterPeloId?id=' + id, super.ObterAuthHeaderJson());
  }

  alterar(empresa: Empresa): Observable<Empresa> {
    return this.http.put<Empresa>(this.urlService + 'empresa/alterar', empresa, super.ObterAuthHeaderJson());
  }

  obterEmpresasMesmoGrupo(id: string): Observable<Empresa[]> {
    return this.http.get<Empresa[]>(this.urlService + 'empresa/obterEmpresasMesmoGrupo?id=' + id, super.ObterAuthHeaderJson());
  }

  alterarCustoMedioAtivo(custoMedioAtivo: boolean, empresaId: string): Observable<Empresa> {
    return this.http.get<Empresa>(`${this.urlService}empresa/alterarCustoMedioAtivo?custoMedioAtivo=${custoMedioAtivo}&empresaId=${empresaId}`, super.ObterAuthHeaderJson());
  }

  alterarOrdemSaidaPorServico(ordemSaidaPorServico: boolean, empresaId: string): Observable<Empresa> {
    return this.http.get<Empresa>(`${this.urlService}empresa/alterarOrdemSaidaPorServico?ordemSaidaPorServico=${ordemSaidaPorServico}&empresaId=${empresaId}`, super.ObterAuthHeaderJson());
  }

  obterListaEmpresaVinculadaModuloFinanceiro(filtroEmpresa: FiltroEmpresa): Observable<ListaPaginadaEmpresa> {
    return this.http.post<ListaPaginadaEmpresa>(`${this.urlService}empresa/obterListaEmpresaVinculadaModuloFinanceiro`, filtroEmpresa, super.ObterAuthHeaderJson());
  }

  adicionarEmpresaModuloFinanceiro(empresaIdModuloFinanceiro: string, empresaIdVincular: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.urlService}empresa/adicionarEmpresaModuloFinanceiro?empresaIdModuloFinanceiro=${empresaIdModuloFinanceiro}&empresaIdVincular=${empresaIdVincular}`, super.ObterAuthHeaderJson());
  }

  removerEmpresaModuloFinanceiro(empresaIdModuloFinanceiro: string, empresaIdDesvincular: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.urlService}empresa/removerEmpresaModuloFinanceiro?empresaIdModuloFinanceiro=${empresaIdModuloFinanceiro}&empresaIdDesvincular=${empresaIdDesvincular}`, super.ObterAuthHeaderJson());
  }
}
