<div class="text-center">
  <div class="text-center">
    <img [routerLink]="''" [src]="logoEmpresa" alt="" class="logo-empresa img-thumbnail mr-3" />
  </div>
  <div class="text-center">
    <div class="mb-2">{{localStorageObject?.empresaSelecionada?.nomeFantasia}}</div>
    <span class="badge badge-success font-tamanho-7" [popover]="popoverTemplatePlanoEmpresa"
      popoverTitle="Itens Liberados" placement="right" container="body"
      triggers="mouseenter:mouseleave">{{planoEmpresa?.plano?.nome}} - {{planoEmpresa?.dataTermino | date: 'dd/MM/yyyy - HH:mm:ss'}}</span>
    <ng-template #popoverTemplatePlanoEmpresa>
      <div class="row">
        <div class="col-md-12">

          <table class="table table-sm table-bordered">
            <thead>
              <tr>
                <th>Item</th>
                <th>Ativo</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of planoEmpresa.plano | keyvalue">
                <tr *ngIf="item?.key !== 'planoBase' && (item?.value === true || item?.key === 'quantidadeAcesso' || item?.key === 'quantidadeFichaTecnica' || item?.key === 'quantidadeMapaProducao')">
                  <td>{{item.key | moduloPlano}}</td>
                  <td *ngIf="item?.value === true" class="text-center">
                    <span  class="badge badge-success">Sim</span>
                  </td>
                  <td  *ngIf="item?.value !== true" class="text-center">
                    <span>{{item?.value}}</span>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </ng-template>
  </div>
</div>
