<ng-container *ngIf="usuarioLogado()">
  <!-- begin #sidebar -->
  <div id="sidebar" class="sidebar" [ngClass]="{ 'sidebar-transparent': pageSidebarTransparent }">
    <!-- begin sidebar scrollbar -->
    <div class="height-full slim" #sidebarScrollbar *ngIf="desktopMode" (scroll)="onScroll($event)">
      <ng-container *ngTemplateOutlet="sidebarNav"></ng-container>
    </div>
    <div class="mobile-scroll" *ngIf="mobileMode" (scroll)="onScroll($event)">
      <ng-container *ngTemplateOutlet="sidebarNav"></ng-container>
    </div>
    <!-- end sidebar scrollbar -->

    <ng-template #sidebarNav>
      <ng-container *ngIf="localStorageObject?.empresaSelecionada">
        <!-- begin sidebar user -->
        <ul class="nav">
          <li class="nav-profile">
            <div class="cover with-shadow border-shadow"></div>
            <div class="info">
              <app-menu-empresa [logoEmpresa]="logoEmpresa()"></app-menu-empresa>
            </div>
          </li>
          <li>
            <img [src]="logoEmpresa()" alt="" class="logo-empresa-mini img-thumbnail mr-3" [tooltip]="localStorageObject?.empresaSelecionada?.nomeFantasia" placement="right" />
          </li>
        </ul>
      </ng-container>

      <ul class="nav">
        <!-- sidebar menu -->
        <ng-container *ngFor="let menu of menus; index as i">
          <ng-container *ngIf="menu?.isTitle; else templateMenuRow">
            <li class="sidenav-divider mb-2"></li>
            <li class="sidenav-header small font-weight-semibold" *ngIf="!isExpand">
              <span>{{ menu?.title }}</span>
            </li>
          </ng-container>
          <ng-template #templateMenuRow>
            <li routerLinkActive="active" #rla1="routerLinkActive" [routerLinkActiveOptions]="{ exact: true }" [ngClass]="{ expand: menu.state === 'expand' }"
              *ngIf="menu.submenu ? podeExibirGrupoModulos(menu.submenu) : podeExibirModulo(menu.moduloId)">
              <a *ngIf="!menu.url" class="pointer" [ngClass]="{'stepIntercomFtp1' : i === 2, 'stepIntercomFtp13': i === 3}"
                (click)="podeExibirGrupoModulosPlano(menu.submenu) ?
                  ((menu.submenu) ? expandCollapseSubmenu(menu, menus, rla1) : '') :
                    abrirModalModuloBloqueadoPlano(menu)">
                <ng-container *ngTemplateOutlet="sidebarMenu; context: { menu: menu }"></ng-container>
              </a>
              <a *ngIf="menu.url" class="pointer" [routerLink]="menu.url">
                <ng-container *ngTemplateOutlet="sidebarMenu; context: { menu: menu }"></ng-container>
              </a>

              <!-- sidebar submenu lvl-1 -->
              <div [class.display-none]="!menu.caret" [@expandCollapse]="menu.state ? menu.state : rla1.isActive && !menu.state  ? 'active' : 'collapse'" *ngIf="menu.submenu">
                <ul class="sub-menu" [ngStyle]="{'margin-top.px': pageSettings.pageSidebarMinified ? -scrollTop : 0}">
                  <ng-container *ngFor="let sub1 of menu.submenu; index as z">
                    <li [class.display-none]="!sub1.title" routerLinkActive="active" #rla2="routerLinkActive" [routerLinkActiveOptions]="{ exact: true }" [ngClass]="{ expand: sub1.state === 'expand' }"
                      *ngIf="podeExibirModulo(sub1.moduloId) && isModuloPlanoVisivel(sub1.moduloPlano)">

                      <a *ngIf="!sub1.url" class="pointer" (click)=" sub1.submenu ? expandCollapseSubmenu(sub1, menu, rla2) : ''">
                        <ng-container *ngTemplateOutlet="sidebarSubMenu; context: { menu: sub1 }"></ng-container>
                      </a>
                      <a *ngIf="sub1.url" class="pointer" [ngClass]="{'stepIntercomFtp2' : z === 2 && i === 2, 'stepIntercomFtp14': z === 0 && i === 3}" (click)="navigateMenu(sub1)">
                        <ng-container *ngTemplateOutlet=" sidebarSubMenu; context: { menu: sub1 }"></ng-container>
                      </a>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </li>
          </ng-template>
        </ng-container>
        <!-- begin sidebar minify button -->
        <li>
          <a href="javascript:;" class="sidebar-minify-btn" (click)="toggleMinified()">
            <em class="fa fa-angle-double-right" *ngIf="isExpand; else iconMinify"></em>
            <ng-template #iconMinify>
              <em class="fa fa-angle-double-left"></em>
            </ng-template>
          </a>
        </li>
        <!-- end sidebar minify button -->
      </ul>
      <ul class="nav" style="bottom: 10px; position: absolute">
        <li class="">
          <img src="/assets/img/CookGo_br2.png" alt="" class="logo logo-cookgo" />
          <img src="/assets/img/CookGo_br2_mini.png" alt="" class="logo logo-cookgo-mini" />
        </li>
      </ul>
    </ng-template>
  </div>
  <div class="sidebar-bg"></div>
  <!-- end #sidebar -->
</ng-container>

<!-- render menu item -->
<ng-template #sidebarMenu let-menu="menu">
  <span class="badge pull-right" *ngIf="menu.badge">{{ menu.badge }}</span>
  <span *ngIf="menu.caret && !menu.state">
    <em class="fas fa-chevron-right float-right"></em>
  </span>
  <span *ngIf="menu.state === 'collapse' && menu.caret">
    <em class="fas fa-chevron-right float-right"></em>
  </span>
  <span *ngIf="menu.state === 'expand' && menu.caret">
    <em class="fas fa-chevron-down float-right"></em>
  </span>

  <em [class]="menu.submenu ? (podeExibirGrupoModulosPlano(menu.submenu) ? menu.icon : 'fa fa-lock') : menu.icon"></em>
  <span *ngIf="menu.title">
    {{ menu?.title }}
    <span class="label label-theme m-l-5" *ngIf="menu.label">{{menu?.label}}</span>
  </span>
</ng-template>

<!-- render submenu item -->
<ng-template #sidebarSubMenu let-menu="menu" id="stepTelaAlimentos1">
  <i *ngIf="!podeExibirModuloPlano(menu.moduloPlano)" class="fa fa-lock"></i>
  <span>{{' ' + menu.title }}</span>
  <em class="fa fa-paper-plane text-theme m-l-5" *ngIf="menu.highlight"></em>
</ng-template>
