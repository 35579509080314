<ng-template #modalMensagemPlano>
  <div class="modal-header">
    <h5 class="modal-title pull-left">
      <span class="">Aviso</span>
    </h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="fecharModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <div class="card card-nobordered">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <span *ngIf="nomeModuloPlano">
              <p class="font-tamanho-11">
                O plano <span class="text-success">{{plano.nome}}</span> vinculado a sua empresa não possui acesso ao
                <span class="text-success">{{nomeModuloPlano | moduloPlano}}</span>.
              </p>
              <p class="font-tamanho-11">Por favor entre em contato para ter mais informações de como obter acesso.</p>
              <p class="text-center"><i class="fa fa-envelope font-tamanho-15"></i><br>contato@cookgo.com.br</p>
              <p class="text-center"><i class="fa fa-phone font-tamanho-15"></i><br>(+55) 61 3081-4040</p>
            </span>

            <span *ngIf="quantidadeAcesso">
              <p class="font-tamanho-11">
                Você já possui a quantidade máxima de Acessos permitidas pelo seu plano: <span
                  class="text-success font-weight-bold">{{quantidadeAcesso}}</span>.
              </p>
              <p class="font-tamanho-11">Por favor, entre em contato para ter mais informações de como obter acessos.</p>
              <p class="text-center"><i class="fa fa-envelope font-tamanho-15"></i><br>contato@cookgo.com.br</p>
              <p class="text-center"><i class="fa fa-phone font-tamanho-15"></i><br>(+55) 61 3081-4040</p>
            </span>
            <span *ngIf="quantidadeFichaTecnica">
              <p class="font-tamanho-11">
                Você já possui a quantidade máxima de Fichas Técnica permitidas pelo seu plano: <span
                  class="text-success font-weight-bold">{{quantidadeFichaTecnica}}</span>.
              </p>
              <p class="font-tamanho-11">Por favor, entre em contato para ter mais informações de como adicionar fichas técnicas ao seu plano.</p>
              <p class="text-center"><i class="fa fa-envelope font-tamanho-15"></i><br>contato@cookgo.com.br</p>
              <p class="text-center"><i class="fa fa-phone font-tamanho-15"></i><br>(+55) 61 3081-4040</p>
            </span>
            <span *ngIf="quantidadeMapaProducao">
              <p class="font-tamanho-11">
                Você já possui a quantidade máxima de Mapas de Produção permitidos pelo seu plano: <span
                  class="text-success font-weight-bold">{{quantidadeMapaProducao}}</span>.
              </p>
              <p class="font-tamanho-11">Por favor, entre em contato para ter mais informações de como adicionar mapas de produção ao seu plano.</p>
              <p class="text-center"><i class="fa fa-envelope font-tamanho-15"></i><br>contato@cookgo.com.br</p>
              <p class="text-center"><i class="fa fa-phone font-tamanho-15"></i><br>(+55) 61 3081-4040</p>
            </span>
            <span *ngIf="texto">
              <p class="font-tamanho-11">
                {{texto}}
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-white" (click)="fecharModal()"><span class="fa fa-times"></span>
      Fechar</button>
  </div>

</ng-template>
