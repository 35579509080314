import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor() {}

  setCache<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  removeCache(key: string) {
    localStorage.removeItem(key);
  }

  getCache<T>(key: string): T {
    return JSON.parse(localStorage.getItem(key));
  }

  montarValorRs(valor: number): string {
    let response = '-';
    if (valor !== undefined) {
      if (valor < 0.01) {
        response = 'R$ 0,00';
      } else {
        response = 'R$ ' + (Math.trunc(valor * 100) / 100).toString();
      }
    } else {
      response = '-';
    }

    return response;
  }

  formatarDecimal(valor: any, decimalCount = 2, decimal = ',', thousands = '.') {
    if (valor === 0 || valor === null) {
      return '-';
    }

    let i: number;

    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = valor < 0 ? '-' : '';

      i = parseInt((valor = Math.abs(Number(valor) || 0).toFixed(decimalCount)));

      const j = i > 3 ? i % 3 : 0;

      return (
        negativeSign +
        (j ? i.toString().substr(0, j) + thousands : '') +
        i.toString().substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
        (decimalCount ? decimal + Math.abs(valor - i).toFixed(decimalCount).slice(2) : '')
      );
    } catch (e) {
      console.log(e);
    }
  }
}
