import { EventEmitter, Injectable } from '@angular/core';
import { Empresa } from 'src/app/shared/models/empresa';
import { TipoUsuarioEnum } from 'src/app/shared/models/enum/tipoUsuario.enum';
import { Usuario } from 'src/app/shared/models/usuario';
import { BaseService } from './base.service';
import { FilterLocalStorage } from 'src/app/shared/models/filter-local-storage';
import { FilterLocalStorageItem } from 'src/app/shared/models/filter-local-storage-item';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageObject extends BaseService {

  notificadorAlterarUsuario = new EventEmitter();
  notificadorAlterarEmpresa = new EventEmitter();
  notificadorAlterarToken = new EventEmitter();

  usuario: Usuario;
  empresaSelecionada: Empresa;
  token: string;
  filterLocalStorageList: Array<FilterLocalStorage>;

  constructor() {
    super();
  }

  notificarAlterarUsuario() {
    this.notificadorAlterarUsuario.emit();
  }

  notificarAlterarEmpresa() {
    this.notificadorAlterarEmpresa.emit();
  }

  notificarAlterarToken() {
    this.notificadorAlterarToken.emit();
  }

  popularVariavelGlobalUsuario(): void {
    this.usuario = this.obterUsuario();
    if (this.usuario) {
      this.notificarAlterarUsuario();
    }
  }

  popularVariavelGlobalEmpresa(): void {
    this.empresaSelecionada = this.obterEmpresaSelecionada();
    if (this.empresaSelecionada) {
      this.notificarAlterarEmpresa();
    }
  }

  popularVariavelGlobalToken(): void {
    this.token = this.obterTokenUsuario();
    if (this.token) {
      this.notificarAlterarToken();
    }
  }

  validarTipoUsuarioResponsavel(): boolean {
    let response = false;
    response = this.usuario?.tipoUsuario === TipoUsuarioEnum.ClienteResponsavel ? true : false;
    return response;
  }

  popularVariavelGlobalFilterLocalStorage(): void {
    this.filterLocalStorageList = this.obterFilterLocalStorageList();
  }

  obterFilterLocalStorageList(): Array<FilterLocalStorage> {
    let filterLocalStorageList = null;

    filterLocalStorageList = JSON.parse(localStorage.getItem('rotulagem.filterLocalStorageList')) as Array<FilterLocalStorage>;

    if (!filterLocalStorageList) {
      filterLocalStorageList = new Array<FilterLocalStorage>();
      localStorage.setItem('rotulagem.filterLocalStorageList', JSON.stringify(filterLocalStorageList));
    }

    return filterLocalStorageList;
  }

  obterFilterLocalStoragePorNameFilter(nameFilter: string): FilterLocalStorage {

    let filterLocalStorage = this.filterLocalStorageList.find(x => x.filterName === nameFilter);

    if (!filterLocalStorage) {
      filterLocalStorage = new FilterLocalStorage();
      filterLocalStorage.filterName = nameFilter;
      filterLocalStorage.filterLocalStorageItem = new Array<FilterLocalStorageItem>();
      this.filterLocalStorageList.push(filterLocalStorage);
    }

    return filterLocalStorage;
  }

  salvarFilterLocalStorage(nameFilter: string, filterLocalStorage: FilterLocalStorage): void {

    const index = this.filterLocalStorageList.findIndex(x => x.filterName === nameFilter);

    if (index !== -1) {
      this.filterLocalStorageList[index] = filterLocalStorage;
    } else {
      this.filterLocalStorageList.push(filterLocalStorage);
    }

    localStorage.setItem('rotulagem.filterLocalStorageList', JSON.stringify(this.filterLocalStorageList));
  }

  removerFilterLocalStorage(nameFilter: string): void {
    const index = this.filterLocalStorageList.findIndex(x => x.filterName === nameFilter);
    this.filterLocalStorageList.splice(index, 1);
    localStorage.setItem('rotulagem.filterLocalStorageList', JSON.stringify(this.filterLocalStorageList));
  }

}





