<!-- begin #header -->
<header *ngIf="usuarioLogado()">
  <div id="header" class="header navbar-default">
    <!-- begin navbar-header -->
    <div class="navbar-header">
      <ng-container *ngIf="this.pageSidebarTwo">
        <button type="button" class="navbar-toggle pull-left" (click)="mobileSidebarToggle()">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <button type="button" class="navbar-toggle pull-right" (click)="mobileRightSidebarToggle()">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </ng-container>
      <a href="/" class="navbar-brand">
        <img src="/assets/img/avatar-empresa_minificada.jpg" alt="" class="img-fluid" />
      </a>
      <button type="button" class="navbar-toggle" (click)="mobileSidebarToggle()" *ngIf="
          !this.pageSettings.pageSidebarTwo &&
          !this.pageSettings.pageWithoutSidebar
        ">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <button type="button" class="navbar-toggle p-0 m-r-0" (click)="mobileMegaMenuToggle()"
        *ngIf="this.pageSettings.pageMegaMenu">
        <span class="fa-stack fa-lg text-inverse m-t-2">
          <em class="far fa-square fa-stack-2x"></em>
          <em class="fa fa-cog fa-stack-1x"></em>
        </span>
      </button>
      <button type="button" class="navbar-toggle" (click)="mobileTopMenuToggle()" *ngIf="
          this.pageSettings.pageTopMenu && this.pageSettings.pageWithoutSidebar
        ">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </div>
    <!-- end navbar-header -->

    <!-- begin header-nav -->

    <ul class="navbar-nav navbar-right">
      <li *ngIf="!tipoAmbiente" class="mt-3 mr-3">
        <span class="badge badge-danger">Ambiente Dev</span>
      </li>
      <!-- <li class="mt-3 mr-3"><span class="badge badge-danger">Ambiente Homolog</span></li> -->
      <li [ngClass]="[this.pageSettings.pageLanguageBar ? 'hidden-xs' : '']"></li>
      <!-- <li class="mt-2">
        <app-modal-onboard-home></app-modal-onboard-home>
      </li> -->
      <li>
        <app-notificacao *ngIf="localStorageObject?.empresaSelecionada?.id?.length > 0"></app-notificacao>
      </li>
      <li class="dropdown navbar-user" ngbDropdown *ngIf="usuarioLogado()">
        <app-menu-usuario></app-menu-usuario>
      </li>
      <li class="divider d-none d-md-block" *ngIf="this.pageSidebarTwo"></li>
      <li class="d-none d-md-block" *ngIf="this.pageSidebarTwo">
        <a href="javascript:;" (click)="toggleSidebarRight()" class="f-s-14">
          <em class="fa fa-th"></em>
        </a>
      </li>
    </ul>
    <!-- end header navigation right -->
  </div>
  <!-- end #header -->
</header>
