import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificacaoService } from 'src/app/core/services/notificacao.service';
import { Notificacao } from 'src/app/shared/models/notificacao';
import { FiltroNotificacao } from 'src/app/shared/models/filtros/filtroNotificacao';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subscription } from 'rxjs';
import * as favicon from 'favico.js';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { Router } from '@angular/router';
import { LocalStorageObject } from '../services/localStorageObject.service';

moment.locale('pt-br');

@Component({
  selector: 'app-notificacao',
  templateUrl: './notificacao.component.html',
  styleUrls: ['./notificacao.component.scss'],
  animations: [
    trigger('icone-animado', [
    state(
      'ativo',
      style({
      })
    ),
    transition('inativo => ativo', [
      animate(
        '1500ms ease-in-out',
        keyframes([
          style({
            transform: 'rotate(0deg)',
            transformOrigin: 'top center',
          }),
          style({
            transform: 'rotate(25deg)',
            transformOrigin: 'top center',
          }),
          style({
            transform: 'rotate(-25deg)',
            transformOrigin: 'top center',
          }),
          style({
            transform: 'rotate(25deg)',
            transformOrigin: 'top center',
          }),
          style({
            transform: 'rotate(-25deg)',
            transformOrigin: 'top center',
          }),
          style({
            transform: 'rotate(25deg)',
            transformOrigin: 'top center',
          }),
          style({
            transform: 'rotate(-25deg)',
            transformOrigin: 'top center',
          }),
          style({
            transform: 'rotate(0deg)',
            transformOrigin: 'top center',
          }),
        ])
      ),
    ]),
    state(
      'inativo',
      style({
      })
    ),
    transition('ativo => inativo', [
      animate(
        '500ms ease-in-out',
        keyframes([
          style({
            transform: 'rotate(0deg)',
            transformOrigin: 'top center',
          }),
        ])
      ),
    ]),
  ]),
]
})
export class NotificacaoComponent implements OnInit, OnDestroy {
  @BlockUI() blockUI: NgBlockUI;

  listaNotificacao: Notificacao[];
  filtroNotificacao: FiltroNotificacao;
  config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    maxScrollbarLength: 363,
  };

  requestLoopNotificacao: Subscription;
  fav: any;
  iconeAnimado = 'inativo';
  animacaoAtiva = false;
  audio = new Audio('/assets/sound/bigbox.ogg');

  constructor(
    private readonly notificacaoService: NotificacaoService,
    private readonly toastr: ToastrService,
    private readonly router: Router,
    private readonly localStorageObject: LocalStorageObject
  ) {
    this.filtroNotificacao = new FiltroNotificacao();
    this.listaNotificacao = new Array<Notificacao>();
  }

  ngOnInit(): void {


    this.filtroNotificacao.empresaId = this.localStorageObject?.empresaSelecionada?.id;
    this.criarFavicon();

    this.requestLoopNotificacao = this.notificacaoService.notificadorLoop.subscribe(() => {
      this.obterListaLoop();
    });

    this.obterListaNotificacao();

    setTimeout(() => {
      this.notificacaoService.notificarLoop();
    }, 3000);
  }

  ngOnDestroy() {
    if (this.requestLoopNotificacao) {
      this.requestLoopNotificacao.unsubscribe();
    }
  }

  playAudio() {
    // this.audio.play();
  }

  onEndAnimate(event: any) {
    if (this.animacaoAtiva) {
      this.iconeAnimado = 'ativo';
      if (event.toState === 'ativo') {
        setTimeout(() => {
          this.iconeAnimado = 'inativo';
        }, 0);
      }
    }
  }

  criarFavicon() {
    this.fav = new favicon({
      position: 'up',
      animation: 'popFade',
      bgColor: '#d10000',
      fontFamily: 'Roboto',
    });
  }

  resetarFavicon() {
    this.fav.reset();
  }

  abrirDropDownNotificacao() {
    this.obterListaNotificacao();
  }

  obterListaLoop() {
    this.animacaoAtiva = false;
    this.iconeAnimado = 'inativo';

    this.notificacaoService.obterLista(this.filtroNotificacao)
      .subscribe((response) => {
        this.listaNotificacao = response;
        this.resetarFavicon();

        if (this.listaNotificacao.length > 0) {
          this.listaNotificacao.forEach((notificacao) => {
            notificacao.tempoPassouNotificacao = this.tempoPassou(
              notificacao.dataCadastro
            );
          });
          this. animacaoAtiva = true;
          this.iconeAnimado = 'ativo';
          this.playAudio();
          this.fav.badge(this.listaNotificacao.length);
          const newTitle = '(' + this.listaNotificacao.length + ') ' + 'CookGo';
          document.title = newTitle;
        } else {
          this.animacaoAtiva = false;
          this.iconeAnimado = 'inativo';
          const newTitle = 'CookGo';
          document.title = newTitle;
        }

        setTimeout(() => {
          this.notificacaoService.notificarLoop();
        }, 10000);

      }, error => {
        setTimeout(() => {
          const token = this.localStorageObject.token;
          if (token) {
            this.obterListaLoop();
            console.log(`erro no request`);
          }
        }, 10000);
      });
  }

  obterListaNotificacao() {
    this.blockUI.start();
    this.resetarFavicon();
    this.animacaoAtiva = false;
    this.iconeAnimado = 'inativo';
    this.notificacaoService
      .obterLista(this.filtroNotificacao)
      .subscribe((response) => {
        this.listaNotificacao = response;

        if (this.listaNotificacao.length > 0) {
          this.listaNotificacao.forEach((notificacao) => {
            notificacao.tempoPassouNotificacao = this.tempoPassou(
              notificacao.dataCadastro
            );
          });
          this.animacaoAtiva = true;
          this.iconeAnimado = 'ativo';
          this.fav.badge(this.listaNotificacao.length);
          const newTitle =
            '(' + this.listaNotificacao.length + ') ' + 'Cookgo';
          document.title = newTitle;
        } else {
          this.animacaoAtiva = false;
          this.iconeAnimado = 'inativo';
          const newTitle = 'Cookgo';
          document.title = newTitle;
        }

        this.blockUI.stop();
      });
  }

  excluirNotificacao(id: string) {
    this.notificacaoService.excluir(id).subscribe((response) => {
      this.toastr.success('Notificação excluída com sucesso.');
      this.obterListaNotificacao();
    });
  }

  visualizarNotificacao(notificacao: Notificacao) {
    notificacao.visualizado = true;

    this.notificacaoService.alterar(notificacao).subscribe((response) => {
      this.obterListaNotificacao();

      if (response.movimentacaoId) {
        this.router.navigate(['/estoque/recebimento-itens', notificacao.movimentacaoId]);
      }

      if (response.movimentacaoTransferenciaId) {
        this.router.navigate(['/estoque/movimentacao-transferencia-recebimento', notificacao.movimentacaoTransferenciaId]);
      }
    });
  }

  marcarTodasNotificacoesComoVisualizada() {
    this.notificacaoService
      .marcarTodasNotificacoesComoVisualizada(this.filtroNotificacao)
      .subscribe((response) => {
        this.toastr.success(
          'Todas Notificações foram marcadas como visualizadas.'
        );
        this.obterListaNotificacao();
      });
  }

  tempoPassou(data: Date): string {
    let retorno = '';
    if (data) {
      retorno = moment(data).fromNow();
    }
    return retorno;
  }
}
